body {
    margin: 0;
    font-family: 'Outfit', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #2d2d2d;
    color: #fff;
}

button,
input {
    font-family: 'Outfit', sans-serif;
}

a {
    color: #99ffd3;
}


.app-wrapper {
    max-width: 960px;
    margin: 0 auto 200px;

    * {
        line-height: 1.5em;
        box-sizing: border-box;
    }

    strong {
        font-weight: 600;
    }

}

.connected-status {
    text-align: center;
    background-color: #414141;
    padding: 1em 0.5em;
    font-size: 13px;
    color: #ffffff77;
}