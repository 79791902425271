:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --padding: 1.5em 3em;
}

.mintbox {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px 2px 0 0;

}

.mintable-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: var(--padding);
    height: 250px;

    >div {
        margin: 0 0;
        width: 100%;
        text-align: center;
    }

    img {
        width: 128px;
        height: 128px;
        background-color: #00000033;
        box-shadow: 0 0 15px 0 #00000033, 0 2px 5px 0 #00000033;
        // border: 4px solid #000;
    }

    .id {
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        opacity: 0.35;
    }
}

.mintable-action-bar {
    padding: var(--padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
    background: rgba(0, 0, 0, 0.1);

    div:last-child {
        text-align: right;
    }

    .icon {
        margin: 0 auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(-2px);
        z-index: 2;
    }

    button {
        background: #888;
        color: #fff;
        border: 2px solid black;
        border-radius: 4px;
        text-transform: uppercase;
        padding: 1em 2em;
        font-weight: 600;
        letter-spacing: 1px;
        cursor: pointer;
        transition: 0.1s all ease-in-out;

        &:disabled {
            opacity: 0.2;
        }

        &:hover {
            // border: 4px solid black;
            transform: scale(1.05);
            box-shadow: 3px 3px 15px 0 rgba($color: #000000, $alpha: 0.3);
        }
    }

    div p span {
        font-size: 12px;
        // margin-top: -2px;
        display: block;
        opacity: 0.35;
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: auto
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #00000066;
    opacity: 1;
    // border-radius: 50%;
    animation: lds-ripple 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    animation-delay: -1.5s;
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }

    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}