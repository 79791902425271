.header {
    margin: 3em 0 1.5em;
    text-align: center;

    h1 {
        font-size: 62px;
        line-height: 1.2em;
        font-weight: 800;
    }

    h2 {
        font-size: 19px;
        line-height: 1em;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
}

.credits {
    text-align: center;
    background: #222;
    padding: 0.5em;
    color: #888;
    text-transform: uppercase;

    a {
        color: #888;

    }
}