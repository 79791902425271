.footer {
    display: flex;
    justify-content: space-between;
    padding: 1em 3em;
    background: #7ea496; // #99ffd355;
    font-size: 16px;
    border-radius: 0 0 2px 2px;

    a {
        color: #295042;
    }

    span {
        font-weight: 400;
        color: #29504299;
    }

    >div:nth-child(1) {
        font-weight: 600;
    }

    >div {
        display: flex;
        gap: 2em;
        justify-content: center;

        a {
            display: block;
        }
    }
}